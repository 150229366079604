@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Oswald&family=Roboto+Condensed:wght@400;700&family=Roboto:wght@400;700&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
  }

  .container-login {
    width: 400px;
    margin: 0 auto;
    margin-top: 100px;
    background-color: #fff;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  h1 {
    text-align: center;
    color: #333;
  }

  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 3px;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }

  input[type="submit"] {
    width: 100%;
    background-color: #0E83E4;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 3px;
    cursor: pointer;
    transition: ease-in-out 0.2s;
  }

  input[type="submit"]:hover {
    background-color: #0c63a9;
  }
