@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Oswald&family=Roboto+Condensed:wght@400;700&family=Roboto:wght@400;700&display=swap');


.sidebar{
    margin: 0;
    padding: 0;
    width: 300px;
    background-color: #eeeeee;
    position: fixed;
    height: 100%;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
}

.sidebar a{
    display: block;
    color: #3d3d41;
    padding: 16px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: ease-in-out .4s;
    background-color: #eeeeee;
}


.sidebar a svg{
    margin-left: 20px;
    margin-right: 10px;
}

.sidebar a:hover{
    background-color: #e8e8e8;
    transform: scale(1.00);
    color: #0E83E4;
}

div.content{
    
    padding: 1px 16px;
}

.trackage-title{
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0E83E4;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-shadow: 0px -22px 21px 19px rgba(0,0,0,0.38);
-moz-box-shadow: 0px -22px 21px 19px rgba(0,0,0,0.38);
box-shadow: 0px -22px 21px 19px rgba(0,0,0,0.38);

}

.trackage-title h1{
    font: 26px 'Oswald', sans-serif;
    text-align: right;
    margin-top: -2px;
 }
.trackage-title img{
    max-width: 50%;
    max-height: 50%;
    width: auto;
    height: auto;
    margin-left: -10px;
    
}

.avatar img{
    width: 80px;
    border: #00991c;
    border-style:solid;
    border-width: 2px;
    border-radius: 50%;
    padding: 2px;
    margin-left: 10px;
    -webkit-box-shadow: 0px 0px 74px 1px rgba(0,0,0,0.25);
-moz-box-shadow: 0px 0px 74px 1px rgba(0,0,0,0.25);
box-shadow: 0px 0px 74px 1px rgba(0,0,0,0.25);
}




.user-infos{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-around;
    margin-bottom: 10px;
    margin-top: 50px;
}

.user-infos p{
    
    padding-right: 15px;
    font: 17px 'Roboto', sans-serif;
    font-weight: 400;
    color: rgb(76, 89, 123);
    margin-left: 10px;
    padding-bottom: 10px;
}

.signout{
    cursor: pointer;
    transition: all .2s ease-in-out; 
}
.signout:hover{
    transform: scale(1.1); 
}



@media screen and (max-width: 700px){
    .sidebar{
        width: 100%;
        height: 400px;
        position: relative;
    }

    div.content{
        margin-left: 0;
    }

    .sidebar a{
        float: left;
    }

    .avatar{
        display: none;
    }
}

.voltarhubp{
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    margin-bottom: 10px;
    color: #0E83E4;
    margin-left: 10px;
}

.voltarhub{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    transition: ease-in-out .2s;
}

.voltarhub:hover{
    transform: scale(1.1);
}

.adminp{
    color: #bdbdbd;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    margin: 10px;
}

.container-items{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding-left: 20px;
    padding-right: 20px;
}

.cityselect{
    
}
.icon-title{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
   
}
/* Estilização do componente select */
.select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .select-wrapper select {
    appearance: none;
    background-color: transparent;
    padding: 10px 30px 10px 10px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    cursor: pointer;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .select-wrapper select:hover,
  .select-wrapper select:focus {
    border-color: #888;
  }
  
  .select-wrapper::before {
    
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: #999;
    pointer-events: none;
  }
  
  /* Ajuste para navegadores WebKit (Chrome, Safari) */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .select-wrapper select {
      padding-right: 30px;
    }
  }
  

  .container-items-check{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    
    padding-left: 20px;
    padding-right: 20px;
    
  }

  label{
    margin-bottom: 10px;
  }

  .locked{
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    gap: 3px;
  }
  .locked label{
    margin-top: -4px;
  }

  .login-section{
    background-color: #0E83E4;
    width: 100%;
    position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
  }

  .login-section p{
   color: white;
   font-family: 'Montserrat', sans-serif;
   font-size: 18px;
   margin-left: 20px;
  }

  .tooltips {
    color: #222;
    background-color: rgb(0, 247, 255);
  }

  .loggedasdiv{
    display: flex;
    flex-direction: column;
    
  }

  .loggedasdiv p{
    padding-top: 10px;
    
    margin: 0;
  }

  .loggedasdiv .loggedas{
    font-size: 13px;
    color: rgba(226, 226, 226, 0.641);
  }

  .loggedasdiv .uemail{
    font-size: 14px;
    padding-bottom: 10px;
  }

  .logoutdiv{
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;
    padding: 10px;
  }

  .ocultar {
    display: inline-block;
    padding: 5px 5px;
    background-color: #0E83E4;
    color: #fff;
    font-size: 13px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .ocultar:hover {
    background-color: #0d66af;
  }
  
  .ocultar:active {
    background-color: #0E83E4;
  }

  .ri-download{
    cursor: pointer;
  }