
body{
    background-color: #eeeeee;
    
}

        #cesiumContainer {
            width: 100%;
            height: 600px;
            margin: 0;
            padding: 0;
        }

        .cesium-viewer-timelineContainer {
            display: none;
        }
        .cesium-viewer-animationContainer {
            display: none;
        }
        .cesium-viewer-bottom {
            visibility: hidden;
        }
        .cesium-viewer-geocoderContainer{
            visibility: hidden;
        }


        .container{
            display: flex;
            background-color: white;
            border-radius: 5px;
            padding: .8em;
            align-items: center;
            margin-top: 10px;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }

        div.content{
            margin-left: 0;
            height: 100vh;
            padding: 0;
        }

        @media screen and (min-width: 700px) {
            div.content {
              margin-left: 300px;
            }
          }
        .v1{
            height: 100vh;
        }

        